import React from 'react';

const ContentAutomatedLinkResolution = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.288 42.9783C18.4312 42.9789 15.6247 42.2266 13.1514 40.7969C10.6781 39.3672 8.62538 37.3107 7.20014 34.8348C5.7749 32.3589 5.02742 29.551 5.03324 26.6942C5.03905 23.8374 5.79809 21.0325 7.2334 18.5624C8.66871 16.0924 10.7296 14.0444 13.2087 12.6248C15.6878 11.2052 18.4974 10.4644 21.3541 10.4767C24.2109 10.489 27.0139 11.254 29.4807 12.6949C31.9475 14.1358 33.9912 16.2015 35.4052 18.6839C35.4888 18.869 35.5008 19.0787 35.4388 19.2722C35.3769 19.4657 35.2454 19.6293 35.0697 19.7314C34.8941 19.8336 34.6866 19.8671 34.4878 19.8252C34.289 19.7833 34.1128 19.6689 33.9933 19.5046C32.2416 16.4186 29.4266 14.0758 26.0739 12.9133C22.7212 11.7509 19.06 11.8485 15.774 13.1878C12.4879 14.5272 9.80175 17.0166 8.21687 20.1915C6.63198 23.3664 6.25666 27.0097 7.16127 30.4409C8.06587 33.8722 10.1884 36.8567 13.1326 38.8376C16.0767 40.8185 19.6411 41.6605 23.1604 41.2057C26.6796 40.7509 29.9133 39.0304 32.2572 36.3661C34.601 33.7019 35.8951 30.276 35.8977 26.7275C35.8861 25.6221 35.7541 24.5212 35.5037 23.4445C35.4741 23.3388 35.4659 23.2283 35.4797 23.1195C35.4935 23.0106 35.5288 22.9054 35.5839 22.8105C35.639 22.7156 35.7126 22.6328 35.8003 22.5668C35.888 22.5008 35.988 22.4532 36.0945 22.4265C36.2001 22.4023 36.3094 22.3994 36.4163 22.4177C36.5231 22.436 36.6253 22.4752 36.7168 22.5331C36.8084 22.5911 36.8879 22.6668 36.9501 22.7556C37.0123 22.8443 37.0563 22.9447 37.0795 23.0505C37.3957 24.3061 37.5502 25.5967 37.5392 26.8914C37.454 31.1595 35.7091 35.2268 32.6752 38.2301C29.6413 41.2333 25.5568 42.9364 21.288 42.9783Z"
      fill="#FCFCFD"
    />
    <path
      d="M21.2892 48.0005C17.6505 47.9976 14.073 47.0625 10.898 45.285C7.72293 43.5074 5.05627 40.9462 3.1517 37.8457C1.24712 34.7452 0.168349 31.2087 0.0181609 27.573C-0.132027 23.9374 0.651307 20.3238 2.29367 17.0768C3.93603 13.8297 6.38272 11.0573 9.40033 9.0239C12.4179 6.99052 15.9058 5.76383 19.5319 5.46076C23.158 5.15768 26.8015 5.78838 30.1148 7.29262C33.428 8.79687 36.3008 11.1245 38.4594 14.0538C38.59 14.2323 38.6443 14.4556 38.6104 14.6742C38.5766 14.8928 38.4575 15.089 38.279 15.2196C38.1005 15.3502 37.8772 15.4046 37.6586 15.3707C37.4401 15.3369 37.2442 15.2174 37.1136 15.0389C34.3336 11.2668 30.2998 8.60931 25.7369 7.5435C21.1739 6.47769 16.3805 7.07363 12.2173 9.22388C8.05398 11.3741 4.79357 14.938 3.02145 19.2757C1.24932 23.6135 1.08143 28.4408 2.54815 32.8911C4.01487 37.3414 7.02023 41.1232 11.0242 43.5573C15.0282 45.9914 19.7688 46.9183 24.3947 46.1719C29.0207 45.4254 33.2289 43.0544 36.264 39.4844C39.2991 35.9144 40.962 31.3795 40.9545 26.6938C40.9685 24.8609 40.7142 23.0358 40.1995 21.2767C40.1385 21.0677 40.1629 20.8432 40.2676 20.6523C40.3722 20.4615 40.5487 20.3197 40.7577 20.2588C40.8612 20.2286 40.9696 20.2194 41.0767 20.2311C41.1839 20.2428 41.2876 20.2755 41.3821 20.3273C41.573 20.432 41.7143 20.6081 41.7752 20.817C42.3281 22.7267 42.6045 24.7058 42.596 26.6938C42.5873 32.342 40.3397 37.7564 36.3458 41.7503C32.3518 45.7442 26.9375 47.9918 21.2892 48.0005Z"
      fill="#FCFCFD"
    />
    <path
      d="M21.2954 7.02571C21.0777 7.02571 20.869 6.93917 20.7151 6.78525C20.5611 6.63133 20.4746 6.42263 20.4746 6.20495V0.820754C20.4827 0.605671 20.5717 0.401468 20.7239 0.249272C20.8761 0.0970765 21.0803 0.00812494 21.2954 0C21.513 0 21.7217 0.0865341 21.8757 0.240455C22.0296 0.394377 22.1161 0.603077 22.1161 0.820754V6.20495C22.1161 6.42263 22.0296 6.63133 21.8757 6.78525C21.7217 6.93917 21.513 7.02571 21.2954 7.02571Z"
      fill="#FCFCFD"
    />
    <path
      d="M23.4941 1.64151H19.1274C18.9097 1.64151 18.701 1.55497 18.5471 1.40105C18.3932 1.24713 18.3066 1.03843 18.3066 0.820754C18.3066 0.603077 18.3932 0.394377 18.5471 0.240455C18.701 0.0865341 18.9097 0 19.1274 0H23.4941C23.7117 0 23.9204 0.0865341 24.0744 0.240455C24.2283 0.394377 24.3148 0.603077 24.3148 0.820754C24.3148 1.03843 24.2283 1.24713 24.0744 1.40105C23.9204 1.55497 23.7117 1.64151 23.4941 1.64151Z"
      fill="#FCFCFD"
    />
    <path
      d="M11.739 9.3946C11.5913 9.395 11.4465 9.3555 11.3194 9.28038C11.1923 9.20527 11.0877 9.09732 11.0168 8.96779L8.94851 4.86402C8.89286 4.76834 8.85813 4.66188 8.84632 4.55183C8.8345 4.44178 8.84591 4.33052 8.87998 4.22521C8.91405 4.1199 8.96985 4.02294 9.04389 3.94067C9.11793 3.8584 9.20844 3.79285 9.30959 3.74791L12.9537 1.90922C13.1535 1.82713 13.3779 1.82713 13.5777 1.90922C13.6765 1.94135 13.7681 1.9927 13.847 2.06031C13.9259 2.12792 13.9905 2.21044 14.0373 2.30317L16.2696 6.7023C16.3192 6.79931 16.3495 6.9051 16.3581 7.01369C16.3668 7.12228 16.354 7.23185 16.3205 7.3355C16.2869 7.43915 16.2332 7.53511 16.1626 7.61804C16.0919 7.70096 16.0055 7.76924 15.9085 7.81882C15.8115 7.8684 15.7057 7.8983 15.5971 7.90698C15.4885 7.91567 15.3793 7.90285 15.2757 7.86931C15.172 7.83578 15.0761 7.78206 14.9932 7.71141C14.9102 7.64077 14.842 7.55474 14.7924 7.45773L12.9537 3.78037L10.7872 4.89688L12.4616 8.1799C12.5123 8.27666 12.5432 8.38241 12.5525 8.49129C12.5618 8.60017 12.5493 8.70993 12.5157 8.8139C12.482 8.91787 12.4279 9.01404 12.3566 9.09684C12.2853 9.17963 12.1983 9.24733 12.1005 9.29601C11.9922 9.36319 11.8664 9.3975 11.739 9.3946Z"
      fill="#FCFCFD"
    />
    <path
      d="M24.7064 34.7634C24.6023 34.7676 24.4986 34.7494 24.4022 34.7097C24.3059 34.67 24.2191 34.61 24.1482 34.5338L17.3196 27.7377C17.2402 27.6611 17.1768 27.5695 17.1337 27.468C17.0905 27.3664 17.0684 27.2573 17.0684 27.147C17.0684 27.0366 17.0905 26.9271 17.1337 26.8256C17.1768 26.724 17.2402 26.6324 17.3196 26.5558C17.3933 26.4773 17.4821 26.4147 17.5809 26.3719C17.6797 26.3291 17.7862 26.307 17.8939 26.307C18.0016 26.307 18.1085 26.3291 18.2073 26.3719C18.3061 26.4147 18.3949 26.4773 18.4686 26.5558L24.575 32.6622L40.9901 8.1053C41.0492 8.01761 41.1254 7.9425 41.2141 7.88488C41.3028 7.82727 41.4021 7.78825 41.5062 7.76987C41.6104 7.75148 41.7174 7.75411 41.8204 7.77788C41.9235 7.80166 42.0206 7.84597 42.1062 7.90813C42.1965 7.96465 42.2746 8.03906 42.3354 8.12654C42.3962 8.21402 42.4386 8.31276 42.46 8.41709C42.4815 8.52143 42.4817 8.62932 42.4604 8.73369C42.4392 8.83807 42.3968 8.93705 42.3362 9.02464L25.2643 34.4023C25.2064 34.5093 25.1216 34.599 25.0178 34.6624C24.914 34.7258 24.7952 34.7607 24.6736 34.7634H24.7064Z"
      fill="#B85DFF"
    />
  </svg>
);

export default ContentAutomatedLinkResolution;
